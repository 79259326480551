<template>
  <b-row>
    <b-col>
      <b-card>
        <b-card-body>
          <b-row>
            <b-col cols="6">
              <b-row>
                <b-col cols="6">
                  <label for="datepicker-begin">Tanggal</label>
                  <!-- <b-form-datepicker
                    id="datepicker-begin"
                    placeholder="Tanggal Awal"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    title-format="DD-MM-YYYY"
                    v-model="dateFrom"
                  /> -->
                  <date-picker
                    :firstDayOfWeek="1"
                    v-model="dateFrom"
                    name="tanggal_mulai"
                    :lang="lang"
                    :format=formats
                    :disabled-date="notAfterToday"
                    placeholder="Tanggal Mulai"
                    value-type="DD-MM-YYYY"
                  ></date-picker>
                </b-col>
                <b-col cols="6">
                  <label for="datepicker-end">Sampai</label>
                  <!-- <b-form-datepicker
                    id="datepicker-end"
                    placeholder="Tanggal Akhir"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                    title-format="DD-MM-YYYY"
                    v-model="dateTo"
                  /> -->
                  <date-picker
                    :firstDayOfWeek="1"
                    v-model="dateTo"
                    name="tanggal_akhir"
                    :lang="lang"
                    :format=formats
                    :disabled-date="notBeforeToday"
                    placeholder="Tanggal Akhir"
                    value-type="DD-MM-YYYY"
                  ></date-picker>
                </b-col>
              </b-row>
            </b-col>
            <!-- <b-col cols="6">
              <b-row>
                <b-col cols="6"></b-col>
                <b-col cols="6">
                  <label for="search">Cari</label>
                  <b-form-input id="search" placeholder="Cari Disini" v-model="name">
                  </b-form-input>
                </b-col>
              </b-row>
            </b-col> -->
          </b-row>
          <b-row class="mt-1">
            <b-col cols="6">
              <b-row>
                <b-col cols="3">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    :disabled="dateFrom == '' || dateTo == ''"
                    @click="filterAction"
                  >
                    Filter
                  </b-button>
                </b-col>
                <b-col cols="3">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    @click="reset"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <b-col cols="6">
              <b-row>
                <b-col cols="12">
                  <b-button
                    @click="DownloadReport()"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="float-right"
                  >
                    Download Report
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <table-invoice-list
                :names=name
                :filtering=filter
              >

              </table-invoice-list>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormDatepicker,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import TableInvoiceList from "@/views/table/bs-table/TableInvoiceList.vue";
import { EventBus } from "@/eventBus.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: "InvoiceList",
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormDatepicker,
    BFormInput,
    BButton,
    TableInvoiceList,
    DatePicker,
  },
  directives: {
    Ripple,
  },

   methods: {
    DownloadReport() {
      EventBus.$emit("export_invoice");
    },
    reset()
    {
      let _ = this
      _.filter.dateFrom = ''
      _.filter.dateTo = ''
      _.dateFrom = ''
      _.dateTo = ''
      _.name=""
      EventBus.$emit("filters");
    },

    filterAction()
    {
      let _ = this
      // var str = _.dateFrom;
      // var res = str.split("-");
      // _.filter.dateFrom = res[res.length - 1]  + '-' +  res[res.length - 2] +'-'+ res[res.length - 3];

      // var str1 = _.dateTo;

      // console.log(str)
      // var res1 = str1.split("-");
      // _.filter.dateTo = res1[res1.length - 1]  + '-' +  res1[res1.length - 2] +'-'+ res1[res1.length - 3];
      //  console.log(_.filter.dateTo)
      _.filter.dateFrom = _.dateFrom;
      _.filter.dateTo = _.dateTo;
      
      console.log(_.filter);

      EventBus.$emit("filters");
    },
    notBeforeToday(date) {
      let d = this.dateFrom;
      let res = d.split("-");
      return date < new Date(res[res.length-1], (res[res.length-2]-1), res[res.length-3] , 0, 0, 0)  || date > new Date();;
    },
    notAfterToday(date) {
      return date > new Date(new Date().setHours(0, 0, 0, 0));
    },
  },
  data() {
    return {
      dateFrom:'',
      dateTo:'',
      name:'',
      filter:{
        
      },
      export_url_invoice:'',
      formats: "DD/MM/YYYY",
      lang: {
        days: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
        months: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "Mei",
          "Jun",
          "Jul",
          "Agu",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        pickers: [
          "next 7 days",
          "next 30 days",
          "previous 7 days",
          "previous 30 days",
        ],
        placeholder: {
          date: "Select Date",
          dateRange: "Select Date Range",
        },
      },
    }
  },
  mounted() {
    let _ = this;
    if (_.$route.params.successCreate) {
      _.$bvToast.toast("Pengajuan berhasil dibuat", {
        title: "Sukses",
        variant: "success",
        solid: true,
      });
    }
  },
};
</script>