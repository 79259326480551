<template>
  <b-card
    no-body
  >
    <b-table
      striped
      hover
      responsive
      show-empty
      class="position-relative"
      :fields="fields"
      :items="items"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      @sort-changed="sortManual"
    >
     <template #cell(no)="data">
        {{ (currentPage - 1) * perPage + (data.index + 1) }}
      </template>
    
      <template #cell(id)="data">
        {{ data.item.invoiceNo }}
      </template>

       <template #cell(createdAt)="data">
        {{ data.item.createdAt_cast }}
      </template>

       <template #cell(paymentDeadline)="data">
        {{ data.item.paymentDeadline_cast }}
      </template>

      <template #cell(status)="data">
        {{  "(" + data.item.status + "/" + data.item.terminPayment.amount + ")" }}
      </template>

      <template #cell(paid)="data">
        {{ data.item.paid ? 'Paid' : 'Unpaid', }}
      </template>

      <template #cell(file)="data">
        <b-link @click="DowmloadFile(data.item.paymentAttachment)" v-if="data.item.filenamePaymentAttachment">
          <span style="color:blue;"> {{ data.item.filenamePaymentAttachment ? data.item.filenamePaymentAttachment : '-' }} </span>
        </b-link>
      </template>
        
      <template #cell(action)="data">
        <div style="min-width:200px;">
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-if="data.item.attachment != '-' "
              @click="downloadAttachemnt(data)"
          >
              <feather-icon icon="DownloadIcon" />
          </b-button>
          &nbsp;
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="warning"
              class="btn-icon"
              v-if="data.item.paid == false"
              @click="showModal(data)"
              >
              <feather-icon icon="UploadIcon" />
          </b-button>
          &nbsp;
          <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="btn-icon"
              @click="goToDetail(data)"
              >
              <feather-icon icon="EyeIcon" />
          </b-button>
        </div>
      </template>
      <template #empty="scope">
        <h5 class="text-center">Tidak Ada Data</h5>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label=""
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <!-- <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        /> -->
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
          @change="changePage"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
    
    <b-modal ref="my-modal" @ok="submitFile" title="Upload file bukti pembayaran">
        <div class="d-block text-center">
            <b-form-file id="file" ref="file" v-on:change="handleFileUpload()"></b-form-file>
        </div>
    </b-modal>
  </b-card>
</template>

<script>

import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BCard, BFormFile, BLink,
} from 'bootstrap-vue'
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import { EventBus } from "@/eventBus.js";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BFormFile,
    BLink,
  },
  directives: {
      Ripple,
  },
  props: [
    "names",
    "filtering"
  ],
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: 'createdAt',
      tamp:'desc',
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        { key: 'no', label: 'No' },
        { key: 'id', label: 'No Invoice', sortable:true},
        { key: 'createdAt', label: 'Tanggal Invoice', sortable:true  },
        { key: 'paymentDeadline', label: 'Batas Akhir Pembayaran', sortable:true },
        { key: 'status', label: 'Status Termin', sortable:true },
        { key: 'paid', label: 'Status Pembayaran', sortable:true },
        { key: 'file', label: 'Bukti Pembayaran', sortable:true },

        {key: 'action', label: 'Aksi', thStyle: 'width:290px'}
      ],
      /* eslint-disable global-require */
      items: [],
      invoiceID: null,
      file: '',
      name:'',
      dateTo:'',
      dateFrom:'',
      configs:'',
      urls:''
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    let _ = this
    // this.totalRows = this.items.length


    EventBus.$on("filters", () => {
      _.dateFrom = this.filtering.dateFrom
      _.dateTo = this.filtering.dateTo
      _.getListInvoice()
    });


    if (!EventBus._events['export_invoice']) {
        EventBus.$on('export_invoice', () => {
            this.DownloadReporting();
        })
    }

    EventBus.$on("resetfFlter", () => {
      this.resetFilters();
    });
    
    this.getListInvoice()

    var targetDiv = document.getElementsByClassName("text-center my-2");
    targetDiv.innerHTML = "Goodbye world!";
  },
  methods: {
    sortManual(e) {
      this.sortBy = e.sortBy;
      this.sortDesc = e.sortDesc == true
      this.tamp =  this.sortDesc == true ? 'desc' : 'asc'
      this.getListInvoice()
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    downloadAttachemnt(data)
    {
      let url = data.item.attachment
      window.open(url)
    },
    getListInvoice(name="",page=1) {
      let orderBy = this.tamp
      let result = this.sortBy + "." + orderBy
      
      this.configs = '/advertiser/invoices/filter?page=' + page + "&sortBy=" + result + '&search=' + this.name + '&dateFrom=' + this.dateFrom + '&dateTo=' + this.dateTo
      this.urls = this.configs
      axios.get(this.configs)
        .then(resp => {
          let _ = this
          let invoice = resp.data.data
          // let data = invoice.data.map((v,i) => {
          //   let filename = v.filenamePaymentAttachment ? v.filenamePaymentAttachment : '-'
          //   let advAttachment = v.paymentAttachment
          //   let urlFile = v.attachment
          //   return {
          //         id: v.id,
          //         // no: parseInt(i)+parseInt(1),
          //         invoiceNo: v.invoiceNo,
          //         createdAt: v.createdAt_cast,
          //         paymentDeadline: v.paymentDeadline_cast,
          //         status:  "(" + v.status + "/" + v.terminPayment.amount + ")",
          //         paid: v.paid ? 'Paid' : 'Unpaid',
          //         file: filename,
          //         urlFile: urlFile,
          //         attachment: v.attachment ? v.attachment : '',
          //         campaignID: v.terminPayment.campaignID,
          //         advAttachmenturl : advAttachment
          //     }
          // })

          _.items = invoice.data
          _.totalRows = invoice.totalData
          _.perPage = 10
          _.currentPage = invoice.currentPage
        })
        .catch(err => {
          console.log(err.resp)
        })
    },
    showModal(data) {
      let _ = this
      _.invoiceID = data.item.id
      _.$refs['my-modal'].show()
    },
    goToDetail(data) {
      let _ = this
      let campaignID = data.item.terminPayment.campaignID
      // console.log(campaignID)
      _.$router.push({name: 'campaign-detail', params: {id: campaignID}})
    },
    submitFile() {
      let formData = new FormData();
      formData.append('file', this.$refs.file.files[0]);
      axios.put( '/advertiser/invoices/'+this.invoiceID+'/submit-attachment',
        formData,
        {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
        }
      ).then(resp => {
         this.getListInvoice()
         this.$toast({
          component: ToastificationContent,
          props: {
            title: resp.data.message,
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
      .catch(function(){
        console.log('FAILURE!!');
      });
    },
    handleFileUpload(){
      console.log('handleFileUpload')
      console.log(this.$refs.file.files[0]);
      this.file = this.$refs.file.files[0];
    },
    changePage(page) {
      console.log(page)
      this.getListInvoice(this.name,page)
    },
    resetFilters()
    {
      this.dateFrom = null,
      this.dateTo = null,
      this.getListInvoice()
    },

    DowmloadFile(files)
    {

      window.open(files)
    },

    DownloadReporting() {
      let _ = this;
      let url  = this.urls.replaceAll('/advertiser/invoices', '/advertiser/invoices/export')
      axios.get(url, { responseType: "arraybuffer" }).then((response) => {
        if (response) {
          let blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "Daftar Invoice " + new Date();
          link.click();
        }
      });
      // EventBus.$off('export_invoice');
    },
  },
  watch:{
    names:function (newValue) {
      let _ = this
      this.name = newValue
      _.getListInvoice(this.name)
    },
  }
  
}
</script>
